
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DilutionService } from '../services/dilution.service';
import { DilutionRequest, DilutionResponse } from '../models/dilution.model';

@Component({
  selector: 'app-dilution-calculate',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './dilution-calculate.component.html',
  styleUrls: ['./dilution-calculate.component.css']
})
export class DilutionCalculateComponent implements OnInit {
  dilutionForm!: FormGroup;
  result: DilutionResponse | null = null;

  constructor(private fb: FormBuilder, private dilutionService: DilutionService) { }

  ngOnInit(): void {
    this.dilutionForm = this.fb.group({
      dilutionPercentage: [0, [Validators.required, Validators.min(0)]],
      volumeMl: [0, [Validators.required, Validators.min(0)]],
    });
  }

  calculate(): void {
    if (this.dilutionForm.valid) {
      const request: DilutionRequest = this.dilutionForm.value;
      this.result = this.dilutionService.calculateDilution(request);
    }
  }
}
