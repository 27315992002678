<div class="container mt-4">
    <h2>Calcular Diluição de Óleo Essencial</h2>

    <form [formGroup]="dilutionForm" (ngSubmit)="calculate()">
        <div class="form-group">
            <label for="dilutionPercentage">Percentual de Diluição (%)</label>
            <input type="number" class="form-control" id="dilutionPercentage" formControlName="dilutionPercentage"
                placeholder="Insira o percentual de diluição" />
            <div *ngIf="dilutionForm.get('dilutionPercentage')?.invalid && dilutionForm.get('dilutionPercentage')?.touched"
                class="text-danger">
                Por favor, insira um percentual de diluição válido.
            </div>
        </div>

        <div class="form-group mt-3">
            <label for="volumeMl">Volume (ml)</label>
            <input type="number" class="form-control" id="volumeMl" formControlName="volumeMl"
                placeholder="Insira o volume em mililitros" />
            <div *ngIf="dilutionForm.get('volumeMl')?.invalid && dilutionForm.get('volumeMl')?.touched"
                class="text-danger">
                Por favor, insira um volume em mililitros válido.
            </div>
        </div>

        <button type="submit" class="btn btn-primary mt-3" [disabled]="dilutionForm.invalid">Calcular</button>
    </form>

    <div *ngIf="result" class="mt-4">
        <h3>Resultados</h3>
        <p>Gotas Necessárias: {{ result.dropsNeeded }}</p>
    </div>
</div>