import { Injectable } from '@angular/core';
import { DilutionRequest, DilutionResponse } from '../models/dilution.model';

@Injectable({
  providedIn: 'root',
})
export class DilutionService {

  constructor() { }

  calculateDilution(request: DilutionRequest): DilutionResponse {
    const dropsNeeded = this.calculateDrops(request.dilutionPercentage, request.volumeMl);
    return {
      dilutionPercentage: request.dilutionPercentage,
      volumeMl: request.volumeMl,
      dropsNeeded: dropsNeeded,
    };
  }

  private calculateDrops(dilutionPercentage: number, volumeMl: number): number {
    const dropsPerMl = 20; // Assumindo 20 gotas por ml como exemplo
    const totalDrops = (dilutionPercentage / 100) * volumeMl * dropsPerMl;
    return Math.ceil(totalDrops); // Arredondar para cima para garantir gotas inteiras
  }
}